/*!
 *  F-FUNCTIONS
 */

/* ---------------------------- Variables ----------------------------------- */
var windowW = $(window),
    html = $("html"),
    bodyB = $("body"),
    barbaWrapper = $("#barba-wrapper"),
    offsetHeight = 450,
    footer = $("footer");

/* ---------------------------- Inizialize skrollr ----------------------------------- */
function skrollrInit(){
    var s = skrollr.init({
        constants: {
            box: '150p'
        }
    });
    s.refresh();
}

/* ---------------------------- Inizialize wow ----------------------------------- */
new WOW().init();

/* ---------------------------- Inizialize feather ----------------------------------- */
feather.replace()

/* ---------------------------- Initialize bluimp-gallery ----------------------------------- */
function bluimpGallery(){
    var blueimpGallery = $("#blueimp-gallery"),
        blueimpGallerySlides = $("#blueimp-gallery .slides");

    blueimpGallery.on("open", function (){
        blueimpGallery.show();
    });
    blueimpGallery.on("close", function (){
        blueimpGallery.hide();
        blueimpGallerySlides.children().remove();
    });
}

/* ---------------------------- Inizialize slick ----------------------------------- */
function initSlick(){
    var imageGallery = $(".image-gallery"),
        slkControls = $(".slk-controls");

    imageGallery.slick({
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        variableWidth: true,
        appendArrows: slkControls,
        prevArrow: '<span><i data-feather="arrow-left"></i> /</span>',
        nextArrow: '<span> <i data-feather="arrow-right"></i></span>'
    });
    feather.replace();
}

function initMyCursor(){
    var wrapGallery = $(".gallery-work, .gallery-exhibition"),
        wrapSketchPoster = $(".images-sketch, .images-poster"),
        wrapPublicationCover = $(".publications-cover"),
        cursorView = $(".mycursor-view"),
        cursorDrag = $(".mycursor-drag");

    wrapGallery.mousemove(function(e){
        cursorDrag.show();
        cursorDrag.offset({
            left: e.pageX,
            top: e.pageY + 20
        });
    });

    wrapGallery.mouseout(function(){
        cursorDrag.hide();
    });

    wrapSketchPoster.mousemove(function(e){
        cursorView.show();
        cursorView.offset({
            left: e.pageX,
            top: e.pageY + 20
        });
    });

    wrapSketchPoster.mouseout(function(){
        cursorView.hide();
    });

    wrapPublicationCover.mousemove(function(e){
        cursorDrag.show();
        cursorDrag.offset({
            left: e.pageX,
            top: e.pageY + 20
        });
    });

    wrapPublicationCover.mouseout(function(){
        cursorDrag.hide();
    });


}

/* ---------------------------- Sticky footer ----------------------------------- */
function stickyFooter(){
    if (footer.length > 0){
        var docHeight = windowW.height();
        var footerHeight = footer.height();
        var footerTop = footer.position().top + footerHeight;
        if (footerTop < docHeight) {
            footer.css("margin-top", 10 + (docHeight - footerTop) + "px");
        }
    }
}

/* ---------------------------- Custom wrapper functions ----------------------------------- */
function wrapperMainTwoCol(){
    var getWindowHeight = windowW.height();
    $("section.wrap-two-col").css("height", getWindowHeight);
    $(".content-wrap-two-col").perfectScrollbar();
}

function wrapperMainWorksSingle(){
    var getWindowHeight = windowW.height();
    $("section.focus-work-evidence").css("height", getWindowHeight);
    $("#anchorAbout").css("margin-top", getWindowHeight + offsetHeight);
    $("#anchorScroll").css("margin-top", getWindowHeight + offsetHeight);
}

/* ---------------------------- Modal search ----------------------------------- */
function initSearch(){
    var openCtrl = $("#btn-search"),
        closeCtrl = $("#btn-search-close"),
        searchContainer = $(".search"),
        inputSearch = $("#searchInput"),
        query = $("#queryList li, #workFocusList li");

    openCtrl.on("click", function(){
        searchContainer.addClass("search-open");
        inputSearch.focus();
        html.addClass("no-scroll");
        searchContainer.perfectScrollbar();
    });
    closeCtrl.on("click keyup", function(){
        searchContainer.removeClass("search-open");
        html.removeClass("no-scroll");
        inputSearch.blur();
        inputSearch.val("");
    });
    windowW.on("keyup", function(ev){
        // escape key.
        if (ev.keyCode == 27){
            searchContainer.removeClass("search-open");
            html.removeClass("no-scroll");
            inputSearch.blur();
            inputSearch.val("");
        }
    });

    // search hashtag
    query.on("click", function(e){
        e.preventDefault();
        inputSearch.val($(this).text());
        inputSearch.focus();
        return false;
    });
}

/* ---------------------------- Show category image on rel ----------------------------------- */
function showCategoryImg(){
    $(".rel-hover a").mouseover(function (){
        $("#categoryImg").fadeIn(400).css("background-image", "url(" + $(this).attr("rel") + ")");
    }).mouseout(function () {
        $("#categoryImg").fadeOut(150);
    });
}

/* ---------------------------- ScrollTo ----------------------------------- */
function scrollTo(){
    $(".scroll").click(function (){
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname){
            var $target = $(this.hash);
            $target = $target.length && $target || $('[name=' + this.hash.slice(1) + ']');
            if ($target.length) {
                var targetOffset = $target.offset().top;
                $("html, body").animate({scrollTop: targetOffset}, 1200);
                return false;
            }
        }
    });
}

/* ---------------------------- Menu mobile ----------------------------------- */
function menuMobile(){
    var menuMobile = $("#menuMobile"),
        menuMobileLink = $("#menuMobile li a"),
        showBottomMenu = $("#showBottomMenu"),
        header = $("header"),
        logotypeLink = $(".logotype a");

    showBottomMenu.on("click", function (){
        $(this).toggleClass("active");
        $(this).parent().toggleClass("is-active");
        menuMobile.toggleClass("m-menu-open");
        html.toggleClass("no-overflow");
        header.toggleClass("h-visible");
    });

    menuMobileLink.on("click", function (){
        showBottomMenu.removeClass("active");
        menuMobile.removeClass("m-menu-open");
        //showBottomMenu.parent().removeClass("is-active");
        html.removeClass("no-overflow");
    });

    logotypeLink.on("click", function (){
        showBottomMenu.removeClass("active");
        menuMobile.removeClass("m-menu-open");
        html.removeClass("no-overflow");
    });
}

/* ---------------------------- Cookie banner ----------------------------------- */
function cookieBanner(){
    var cookieScripts = function(){
        jQuery.cookiesDirective.loadScript({
            //uri:'external.js',
            appendTo: 'eantics'
        });
    }

    jQuery.cookiesDirective({
        privacyPolicyUri: 'privacy-policy',
        explicitConsent: false,
        position : 'bottom',
        duration: 200,
        message: 'This site uses third-party cookies in anonymous form and does not use or install profiling cookies. For further information read',
        fontColor: '#fcfcfc',
        backgroundColor: '#333',
        backgroundOpacity: '96',
        linkColor: '#41c0be'
    });
}

/* ---------------------------- Extend jquery functions ----------------------------------- */
jQuery.fn.extend({
    colorInstitutional: function (){
        return this.each(function (){
            $(this).css("color", "#222");
        });
    }
});

function cutString(maxLen){
    return function truncateToSpace(idx, text){
        var replacement = "...";
        var truncated = text.substr(0, maxLen);
        if (/[^\s]$/.test(truncated))
            return truncated.replace(/\s[^\s]+$/, "")+replacement;
        else
            return truncated.trim()+replacement;
    }
}

$(".notext").html("");

/* ---------------------------- Document ready ----------------------------------- */
$(function (){
    bodyB.removeAttr("style", "height");
    // Global ready functions
    initSearch();
    stickyFooter();
    scrollTo();
    menuMobile();
    //cookieBanner();

    $("#privacy, #credits").on("show.bs.modal", function (e){
        html.addClass("no-overflow-modal");
    });
    $("#privacy, #credits").on("hide.bs.modal", function (e){
        html.removeClass("no-overflow-modal");
    });

    /* ---------------------------- Instructions mobile ----------------------------------- */
    if (navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i)){
        skrollr.init().destroy();
        $("#anchorAbout").removeAttr("style", "height");
        $("#anchorScroll").removeAttr("style", "height");
    }

    /* ---------------------------- Instructions smart-tv ----------------------------------- */
    if (navigator.userAgent.search(/TV/i) >= 0){
    }
});

/* ---------------------------- Global resize ----------------------------------- */
windowW.resize(function (){
    bodyB.removeAttr("style", "height");
    stickyFooter();
});
