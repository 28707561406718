/*!
 *  VIEW
 */

(function (){
    var FadeTransition = Barba.BaseTransition.extend({
        start: function (){
            Promise
            .all([this.newContainerLoading, this.fadeOut()])
            .then(this.fadeIn.bind(this));
        },

        fadeOut: function (){
            return $(this.oldContainer).animate({ opacity: 0 }).promise();
        },

        fadeIn: function (){
            var _this = this;
            var $el = $(this.newContainer);
            $(this.oldContainer).hide();
            $el.css({
                visibility : 'visible',
                opacity : 0
            });

            $el.animate({ opacity: 1 }, 400, function (){
                _this.done();

            });
        }
    });

    Barba.Pjax.getTransition = function (){
        return FadeTransition;
    };

    /* Barba init page */
    var pageHome = Barba.BaseView.extend({
      namespace: 'home',
      onEnter: function (){
          $("html").scrollTop(0);
          skrollrInit();
          wrapperMainWorksSingle();
          windowW.resize(function (){ wrapperMainWorksSingle(); });
      },
      onEnterCompleted: function (){
          barbaWrapper.attr("class", "main");
          initSlick();
          initMyCursor();
          bluimpGallery();
          if ($(".wrap-post img").hasClass("view")){
              $("img.view").parent().addClass("no-barba");
              $("img.view").parent().attr("data-gallery", "");
          }
      },
      onLeave: function (){
      },
      onLeaveCompleted: function (){
          initSearch();
          menuMobile();
      }
    });

    var pageCategories = Barba.BaseView.extend({
        namespace: 'categorie',
        onEnter: function (){
            wrapperMainTwoCol();
            windowW.resize(function (){ wrapperMainTwoCol(); });
            showCategoryImg();
        },
        onEnterCompleted: function (){
            barbaWrapper.attr("class", "main categories two-col");
        },
        onLeave: function (){
        },
        onLeaveCompleted: function (){
            initSearch();
            menuMobile();
        }
    });

    var pageCategoryRes = Barba.BaseView.extend({
        namespace: 'categoria',
        onEnter: function (){
            skrollrInit();
        },
        onEnterCompleted: function (){
            barbaWrapper.attr("class", "main category-res full-col");
            new WOW().init();
        },
        onLeave: function (){
        },
        onLeaveCompleted: function (){
            initSearch();
            menuMobile();
        }
    });

    var pageWorksSingle = Barba.BaseView.extend({
        namespace: 'singola_opera',
        onEnter: function (){
            $("html").scrollTop(0);
            skrollrInit();
            wrapperMainWorksSingle();
            windowW.resize(function (){ wrapperMainWorksSingle(); });
        },
        onEnterCompleted: function (){
            barbaWrapper.attr("class", "main");
            initSlick();
            if ($(".image-gallery .slick-slide span.caption").length){
                $(".wrap-post").addClass("multiworks");
            }
            initMyCursor();
            bluimpGallery();
            if ($(".wrap-post img").hasClass("view")){
                $("img.view").parent().addClass("no-barba");
                $("img.view").parent().attr("data-gallery", "");
            }
        },
        onLeave: function (){
        },
        onLeaveCompleted: function (){
            initSearch();
            menuMobile();
        }
    });

    var pageExhibitionSingle = Barba.BaseView.extend({
        namespace: 'singola_mostre',
        onEnter: function (){
            $("html").scrollTop(0);
            skrollrInit();
        },
        onEnterCompleted: function (){
            barbaWrapper.attr("class", "main detail full-col");
            initSlick();
            initMyCursor();
            bluimpGallery();
            if ($(".wrap-post img").hasClass("view")){
                $("img.view").parent().addClass("no-barba");
                $("img.view").parent().attr("data-gallery", "");
            }
        },
        onLeave: function (){
        },
        onLeaveCompleted: function (){
            initSearch();
            menuMobile();
        }
    });

    var pageBiography = Barba.BaseView.extend({
        namespace: 'biografia',
        onEnter: function (){
            wrapperMainTwoCol();
            windowW.resize(function (){ wrapperMainTwoCol(); });
        },
        onEnterCompleted: function (){
            barbaWrapper.attr("class", "main biography two-col");
        },
        onLeave: function (){
        },
        onLeaveCompleted: function (){
            initSearch();
            menuMobile();
        }
    });

    var pagePublications = Barba.BaseView.extend({
        namespace: 'publications',
        onEnter: function (){
            wrapperMainTwoCol();
            windowW.resize(function (){ wrapperMainTwoCol(); });
        },
        onEnterCompleted: function (){
            barbaWrapper.attr("class", "main publications two-col");
            initMyCursor();
            bluimpGallery();
        },
        onLeave: function (){
        },
        onLeaveCompleted: function (){
            initSearch();
            menuMobile();
        }
    });

    var pageVideo = Barba.BaseView.extend({
        namespace: 'video',
        onEnter: function (){
            wrapperMainTwoCol();
            windowW.resize(function (){ wrapperMainTwoCol(); });
        },
        onEnterCompleted: function (){
            barbaWrapper.attr("class", "main video two-col");
        },
        onLeave: function (){
        },
        onLeaveCompleted: function (){
            initSearch();
            menuMobile();
        }
    });

    var pageSearchResults = Barba.BaseView.extend({
        namespace: 'risultati_ricerca',
        onEnter: function (){
            $("html").scrollTop(0);
            skrollrInit();
        },
        onEnterCompleted: function (){
            barbaWrapper.attr("class", "main search-results full-col");
        },
        onLeave: function (){
        },
        onLeaveCompleted: function (){
            initSearch();
            menuMobile();
        }
    });

    var pageNotFound = Barba.BaseView.extend({
        namespace: 'notfound',
        onEnter: function (){
            wrapperMainTwoCol();
            windowW.resize(function (){ wrapperMainTwoCol(); });
        },
        onEnterCompleted: function (){
            barbaWrapper.attr("class", "main not-found two-col");
        },
        onLeave: function (){
        },
        onLeaveCompleted: function (){
            initSearch();
            menuMobile();
        }
    });

    pageHome.init();
    pageCategories.init();
    pageCategoryRes.init();
    pageWorksSingle.init();
    pageExhibitionSingle.init();
    pageVideo.init();
    pagePublications.init();
    pageBiography.init();
    pageSearchResults.init();
    pageNotFound.init();

    // barba no-cache
    Barba.Pjax.cacheEnabled = false;

    // barba init
    Barba.Pjax.start();

    /* Barba currentStatus and Re-initialize plugin and functions */
    Barba.Dispatcher.on("newPageReady", function (currentStatus, oldStatus, container){
        //console.log(currentStatus.url);

        bodyB.removeAttr("style", "height");
        // Re-initialize global ready functions
        feather.replace();
        scrollTo();
        //cookieBanner();

        /* ---------------------------- Instructions mobile ----------------------------------- */
        if (navigator.userAgent.match(/Android/i) ||
            navigator.userAgent.match(/webOS/i) ||
            navigator.userAgent.match(/iPhone/i) ||
            navigator.userAgent.match(/iPad/i) ||
            navigator.userAgent.match(/iPod/i) ||
            navigator.userAgent.match(/BlackBerry/i)){
                skrollr.init().destroy();
                $("#anchorAbout").removeAttr("style", "height");
                $("#anchorScroll").removeAttr("style", "height");
        }
    });
})();